
<template>
  <div class="coupons">
      <div class="row">
        <div class="col-lg-8">
            <div class="mb-3 bg-white d-flex align-items-center justify-content-between p-2">
                <h5 class="my-0">
                    Coupon Codes
                </h5>
                <b-button variant="primary" class="px-1 py-0" @click.prevent="popupModalShow = true" type="button">
                    <i class="mdi font-14 mdi-plus"></i></b-button>
            </div>
            <div class="card">
                <div class="card-body">
                    <div v-if="!isLoading" role="tablist" class="">
                        <b-card no-body class="mb-1 shadow-none" v-for="(coupon, index) in coupons" :key="index">
                            <b-card-header header-tag="header" class="bg-transparent px-2" role="tab">
                                <div class="d-flex">
                                    <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                                        <a v-b-toggle="`couponAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                                            <i class="mdi mdi-check mr-1 text-primary"></i>{{coupon.code}} - {{coupon.is_active ? 'Active' : 'Not Active'}}
                                        </a>
                                    </h5>
                                    <span class="float-right">
                                        <b-dropdown variant="light" size="sm" right>
                                            <template slot="button-content">
                                                <i class="mdi mdi-dots-vertical"></i>
                                            </template>
                                            <b-dropdown-item-button @click="initEdit(coupon)"><i class="mdi mdi-square-edit-outline"></i> Edit</b-dropdown-item-button>
                                            <b-dropdown-item-button @click="deleteItem(coupon.id)"><i class="mdi mdi-trash-can-outline"></i> Delete</b-dropdown-item-button>
                                        </b-dropdown>
                                    </span>
                                </div>
                            </b-card-header>
                            <b-collapse :id="`couponAccordion-${index}`" accordion="accordion" role="tabpanel">
                                <b-card-body>
                                    <h6>Multiplier: {{coupon.multiplier}} - {{coupon.multiplier*100}}% deduction will be applied to the total charge.</h6>
                                    <b-card-text>{{coupon.description}}</b-card-text>
                                    <b-card-text>Valid from <strong>{{coupon.start | date(true)}}</strong> to <strong>{{coupon.end | date(true)}}</strong></b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <p v-if="!coupons.length" class="text-center mb-0">No item found</p>
                    </div>
                    <is-loading v-else />
                </div>
            </div>
        </div>
    </div>

    <b-modal centered id="modal-1" v-model="popupModalShow" :title="editMode? 'Update Coupon':'Add Coupon'" 
     header-close-variant="primary" title-class="font-18" hide-footer  @hide="initializeAll()">
        <coupon-form v-if="popupModalShow" @closeMe="initializeAll()"
        :editMode="editMode" :editItem="editItem"/>
    </b-modal>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue';
import CouponForm from '@/components/forms/CouponForm.vue';

export default {
    components: {
        IsLoading,
        CouponForm
    },
    data() {
        return {
            isLoading: false,
            popupModalShow: false,
            editMode: false,
            editItem: null
        }
    },
    computed: {
        coupons(){
            return this.$store.state.coupons
        }
    },
    methods: {
        initEdit(item){
            this.editMode = true;
            this.editItem = {...item}
            this.popupModalShow = true  
        },
        deleteItem(itemId){
            this.confirmDelete({
                text: "You are about to delete a coupon!",
            }).then((result) => {
                if (result.value) {
                this.$store.dispatch("changeLoaderValue",true)
                this.$http.delete(`/coupons/${itemId}/delete`)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("DELETE_COUPON", itemId)
                    }
                })
                }
            });
        },
        initializeAll(){
            this.editMode = false;
            this.editItem = null
            this.popupModalShow = false;
        },
        fetchCoupons() {
            if(this.coupons.length){ return }
            this.isLoading = true
            this.$http.get(`/coupons`)
            .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.$store.commit('SET_COUPONS', response.data.data)
                }
            })
        },
    },
    created(){
        this.fetchCoupons()
    }
}
</script>
