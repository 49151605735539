<template>
    <form class="">
        <div class="form-group mb-3">
            <label for="couponCode">
                Code
                <span class="text-danger">*</span>
            </label>
            <input type="text" v-validate="{required: true}"
            v-model="data.code" id="couponCode" name="code"
            class="form-control" :class="{ 'is-invalid': errors.has('code') }" placeholder="Enter Code" />
            <span class="text-danger invalid-feedback"  v-show="errors.has('code')">{{ errors.first('code') }}</span>
        </div>
        <div class="form-group mb-3">
            <label for="couponMultiplier">
                    Multiplier
                <span class="text-danger">*</span>
            </label>
            <input type="number" v-validate="{required: true}"
            v-model="data.multiplier" id="couponMultiplier" name="multiplier"
            class="form-control" :class="{ 'is-invalid': errors.has('multiplier') }" placeholder="E.g 0.1 for 10% discount" />
            <span class="text-danger invalid-feedback"  v-show="errors.has('multiplier')">{{ errors.first('multiplier') }}</span>
        </div>
        <div class="form-group mb-3">
            <label class="d-block">Start Date</label>
            <date-picker v-model="data.start" placehoder="Choose Date"
            :editable="false" :first-day-of-week="1"
            type="datetime" format="YYYY-MM-DD hh:mm" lang="en"></date-picker>
        </div>
        <div class="form-group mb-3">
            <label class="d-block">End Date</label>
            <date-picker v-model="data.end" placehoder="Choose Date"
            :editable="false" :first-day-of-week="1"
            type="datetime" format="YYYY-MM-DD hh:mm" lang="en"></date-picker>
        </div>
        <div class="form-group mb-3">
            <b-form-checkbox v-model="data.is_active" name="is-active" switch>
                Active
            </b-form-checkbox>
        </div>
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" @click.prevent="editMode? update() : create()" type="button">{{editMode? 'Update':'Submit'}}</b-button>
        </div>
    </form>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
    components:{
        DatePicker,
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                code: "",
                multiplier: null,
                start: null,
                end: null,
                is_active: false,
                description: ""
            },
        }
    },
    methods:{
        create() {
            this.$validator.validateAll().then(result => {
                if (!result) {this.alertError("Form not valid"); return }
                const formData = new FormData()
                this.$store.dispatch("changeLoaderValue", true)
                formData.append('data', JSON.stringify(this.data))
                this.$http.post("/coupons/create", formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("ADD_COUPON", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
           this.$validator.validateAll().then(result => {
                if (!result) {this.alertError("Form not valid"); return }
                const formData = new FormData()
                this.$store.dispatch("changeLoaderValue", true)
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/coupons/${this.editItem.id}/update`,formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("UPDATE_COUPON", response.data.data)
                        this.resetForm()
                    }
                })
            
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
            this.data.start = new Date(this.data.start)
            this.data.end = new Date(this.data.end)
        }
    }
}
</script>

<style>

</style>